const topMenuBarItems = [
  {
    href: '/home-page',
    title: 'Home    ',
    isCurrent: true,
  },

  {
    href: '/home-page/#seller',
    title: 'Sellers    ',
    isCurrent: false,
  },

  {
    href: '/home-page/#provider',
    title: 'Warehouses    ',
    isCurrent: false,
  },

  {
    href: '/home-page/#retailer',
    title: 'Retailers    ',
    isCurrent: false,
  },

  {
    href: "#contact-form",
    title: 'Contact Us    ',
    isCurrent: false,
  }
 


];

const servicesMenu =  [
  {
    href: '/services/data-analytics',
    title: 'Data Analytics'
  },
  {
    href: '/services/managed-analytics',
    title: 'Managed Analytics'
  },
  {
    href: '/services/big-data-services',
    title: 'Big Data Services'
  },
  {
    href: '/services/data-science-consulting',
    title: 'Data Science Consulting'
  },
  {
    href: '/services/business-intelligence',
    title: 'Business Intelligence'
  },
  {
    href: '/services/data-visualization-services',
    title: 'Data Visualization'
  },
  {
    href: '/services/data-management',
    title: 'Data Management'
  },
  {
    href: '/services/artificial-intelligence',
    title: 'Artificial Intelligence'
  }
];

export { servicesMenu, topMenuBarItems };
