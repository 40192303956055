import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpEvent, HttpRequest, HttpHandler  } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from '../../environments/environment.prod';

@Injectable()
export class MessageInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      const cloned = req.clone({
          setHeaders: {authorization: environment.APP_ACCESS_KEY,
                       app_login: environment.APP_LOGIN,
                       app_password: environment.APP_PASSWORD
                        }
      });
      return next.handle(cloned);
    }
}
