import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-testimonials',
  templateUrl: './our-testimonials.component.html',
})
export class OurTestimonialsComponent implements OnInit {

  titleSectionProp: any = {
    class: 'text-center',
    title: 'Testimonials',
    subTitle: '1000+ Retails are Using Uruk',
  };

  List : any[] = [
    {
      img:"one.jpeg",
      name:"Jesus Hilario Martinez",
      designation:"THE MONUMETAL GROCERY OWNER",
      desc:"La Monumental has found a true ally in Uruk Ecommerce, making merchandise acquisition a breeze. With an efficient and user-friendly platform, we've streamlined the procurement process, saving both time and effort. The dedicated support from Uruk has been invaluable, ensuring that we can source products swiftly and with confidence. The financial savings we've achieved through Uruk's competitive commission structures have empowered us to reinvest in our business, making this partnership a game-changer for our retail success."
    },
    {
      img:"two.jpg",
      name:"Manuel Castillo",
      designation:"Seller",
      desc:"Joining Uruk Ecommerce has been a game-changer for us as sellers. The platform's wide variety of products and services has boosted our earnings significantly. The commission structures are competitive, and the quick order placement and commission processing ensure that we're making more money and getting paid faster than ever before. Uruk Ecommerce has truly transformed the way we do business, making it efficient and profitable."
    },
    {
      img:"three.jpeg",
      name:"Miguel Lopez Martes ",
      designation:"LA MUNDIAL WAREHOUSE",
      desc:"“Uruk Ecommerce has been a catalyst for our warehouse's growth. The platform's diverse offerings have boosted our sales significantly. Since joining, we've tapped into new markets and experienced exponential growth in our business. Uruk is the key to unlocking untapped potential..”"
    }
  ];
  
  constructor() { }

  ngOnInit() {
  }

}
