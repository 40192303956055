import {Component, Input, OnInit} from '@angular/core';
import { SystemServices } from 'src/app/app.services/system.services';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {

  @Input() logoImg: string;
  @Input() contactInfo: any;

  constructor(private scrollingToSection : SystemServices) { }

  ngOnInit() {
  }

  scrollToPage(sectionName: string) {
    // Change the status in the child module
    // Notify the parent module about the status change
    this.scrollingToSection.moveToActiveSection(sectionName);
  }



}
