import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { XaminModule } from './xamin/xamin.module';
import { HomePageModule } from './home-page/home-page.module';
import { LandingPage2Module } from './landing-page2/landing-page2.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms'; // Import ReactiveFormsModule
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { SystemServices } from './app.services/system.services';
import { NgxSpinnerModule } from "ngx-bootstrap-spinner";
import { NgxSpinnerService } from "ngx-bootstrap-spinner";
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { MessageInterceptor } from './app.interceptors/message.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SnotifyModule, SnotifyService, ToastDefaults } from 'ng-snotify';

@NgModule({
  declarations: [
    AppComponent,
  ],
  exports: [

  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    XaminModule,
    HomePageModule,
    LandingPage2Module,
    AppRoutingModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    SnotifyModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [SystemServices, NgxSpinnerService,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: MessageInterceptor,
      multi: true,
    },  
    { provide: 'SnotifyToastConfig', useValue: ToastDefaults}, SnotifyService
  ],
  bootstrap: [AppComponent,  /* ... */]
})
export class AppModule { }
