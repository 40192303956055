import { Injectable } from '@angular/core';
import { HttpClient,
         HttpParams } from '@angular/common/http';
import { Observable, throwError, Subject  } from 'rxjs';
import { catchError } from 'rxjs/operators'; 

@Injectable({
  providedIn: 'root',
})

export class SystemServices {

  private sectionChanged = new Subject<string>();

  moveToActiveSection(pageSeciton: string) {
    this.sectionChanged.next(pageSeciton);
  }

  getStatusObservable() {
    return this.sectionChanged.asObservable();
  }



  // APIs URL
  private SERVICE_DNS = 'apis.urukecommerce.com'; 
  private SERVICE_PORT = '5050';
  private apiUrl = `http://${this.SERVICE_DNS}:${this.SERVICE_PORT}`;
  public  ROOT_URL   : string = this.apiUrl + '/';
  private SYSTEM_URL : string = 'messages/';

  constructor(public _httpClient: HttpClient) { }
  

  /**
    INSERT MESSAGES
  -------------------------------------------------------------------------------------------------*/
  insertMessages(form: any) :Observable <any> {
    return this._httpClient.post(this.ROOT_URL + this.SYSTEM_URL + 'messages-online', form.value)
    .pipe(
    catchError( this.handleError) )
  }


  /**
  * Handle Errors
  */
  handleError(err) {

    let errMessage: string;

    if (err instanceof Response) {
       // tslint:disable-next-line:prefer-const
       let body = err.json() || '';
       // tslint:disable-next-line:prefer-const
      //  let error = body.error || JSON.stringify(body);
      var error = 'no se '
       errMessage = `${err.status} - ${err.statusText} || '' ${error}`;
  
    } else {
      errMessage = err.messsage ? err.message : err.toString();
    }

    return throwError(errMessage);
  }






}
