import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from "@angular/router";
import { XaminModule } from "../xamin/xamin.module";
import { WhyChooseUsComponent } from './components/why-choose-us/why-choose-us.component';
import { CardSectionComponent } from './components/card-section/card-section.component';
import { ServiceComponent } from './components/service/service.component';
import { ProcessComponent } from './components/process/process.component';
import { IndexComponent } from './index/index.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { NavTabsComponent } from './components/nav-tabs/nav-tabs.component';
import { ClientCounterComponent } from './components/client-counter/client-counter.component';
import { OurTestimonialsComponent } from './components/our-testimonials/our-testimonials.component';
import { OurBlogComponent } from './components/our-blog/our-blog.component';
import { OurClientComponent } from './components/our-client/our-client.component';
import { OurPartnerComponent } from './components/our-partner/our-partner.component';
import { RevolutionSlider3Module } from './components/revolution-slider3/revolution-slider3.module';
import { SellerComponent } from './components/seller/seller.component';
import { ProviderComponent } from './components/providers/providers.component';
import { RetailersComponent } from './components/retailers/retailers.component';
import { JoinUsComponent } from './components/join-us/join-us.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-bootstrap-spinner";

const routes: Routes = [
  {
    path: '',
    component: IndexComponent,
    children: [
    ]
  }
];

@NgModule({
  declarations: [WhyChooseUsComponent, CardSectionComponent, ServiceComponent, ProcessComponent, IndexComponent, AboutUsComponent, NavTabsComponent, ClientCounterComponent, OurTestimonialsComponent, OurBlogComponent, OurClientComponent, OurPartnerComponent, SellerComponent, ProviderComponent, RetailersComponent, JoinUsComponent,ContactFormComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    XaminModule,
    RevolutionSlider3Module,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,

  ]
})
export class HomePageModule { }
