import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-choose-us',
  templateUrl: './why-choose-us.component.html',
})
export class WhyChooseUsComponent implements OnInit {

  List : any ={
    title:"WHY CHOOSE US",
    subtitle : "Why Choose Uruk",
    desc : "In a world filled with ecommerce options, Uruk Ecommerce stands out as the clear choice for individuals and businesses looking to amplify their success. We are more than just a platform; we are the driving force behind your dreams. At Uruk, sellers and providers unite in a mutually beneficial partnership, creating a synergy where both parties thrive. Sellers gain access to a diverse product range, while providers expand their market reach. Here's why you should choose Uruk: Unmatched Opportunities, Lucrative Commissions, Hassle-Free Transactions, Exclusive Resources, and Global Reach. Join us, and together, we'll redefine what's possible in the world of ecommerce."
  };
  constructor() { }

  ngOnInit() {
  }

}
