import { Component, OnInit } from '@angular/core';
import * as Notiflix from 'notiflix';

@Component({
  selector: 'app-retailers',
  templateUrl: './retailers.component.html'
})
export class RetailersComponent implements OnInit {

  titleSectionProp1: any = {
    title: 'Retailers why choose us',
    subTitle: 'For Retailers',
    description: "At Uruk Ecommerce, our dedication lies in enabling retailers with a suite of unparalleled advantages, streamlining procurement processes and offering access to a diverse range of in-demand products.",
    class: 'text-left'
  };

  titleSectionProp2: any = {
    title: 'About US',
    subTitle: 'Focused on Actionable Insights',
    description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted many desktop.',
    class: 'text-left'
  };


  checkList1: any[] = ['Endless Opportunities: As a seller with Uruk Ecommerce, you have the freedom to sell products from a wide range of providers. No more restrictions - you can offer your customers exactly what they want.',

  "Generous Commissions: Enjoy competitive commissions for every sale you make. The more you sell, the more you earn. It's that simple!",

  "Effortless Transactions: We take care of payment processing and logistics, so you can focus on what you do best – selling!",

  "Exclusive Seller Resources: Benefit from our exclusive resources and support, including sales analytics, marketing tools, and expert advice. We're dedicated to helping you succeed.",

]


  checkList2: any[] = ['Generous Commissions:</b> Enjoy competitive commissions for every sale you make. The more you sell, the more you earn. Its that simple!'];


  // checkList3: any[] = ['Effortless Transactions:** We take care of payment processing and logistics, so you can focus on what you do best – selling!'];



  constructor() { }

  ngOnInit() {
  }

  scrollToPage() {
    Notiflix.Notify.info("This option are not available yet, we are working on it..!", {
      position: "right-top", // You can adjust the notification position
      width: "400px", // Set the desired width
      timeout: 5000, // The notification will automatically close after 3 seconds
    });
  }


}
