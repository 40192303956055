import { Component, OnInit } from '@angular/core';
import { SystemServices } from 'src/app/app.services/system.services'; 

@Component({
  selector: 'app-seller',
  templateUrl: './seller.component.html'
})
export class SellerComponent implements OnInit {

  titleSectionProp1: any = {
    title: 'Sellers why choose us',
    subTitle: 'For Sellers',
    description: 'At Uruk Ecommerce, we believe in empowering sellers by offering a range of unbeatable advantages:',
    class: 'text-left'
  };

  titleSectionProp2: any = {
    title: 'About US',
    subTitle: 'Focused on Actionable Insights',
    description: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.It is a long established fact that a reader will be distracted many desktop.',
    class: 'text-left'
  };


  checkList1: any[] = ['Endless Opportunities: As a seller with Uruk Ecommerce, you have the freedom to sell products from a wide range of providers. No more restrictions - you can offer your customers exactly what they want.',

  "Generous Commissions: Enjoy competitive commissions for every sale you make. The more you sell, the more you earn. It's that simple!",

  "Effortless Transactions: We take care of payment processing and logistics, so you can focus on what you do best – selling!",

  "Exclusive Seller Resources: Benefit from our exclusive resources and support, including sales analytics, marketing tools, and expert advice. We're dedicated to helping you succeed.",

]


  checkList2: any[] = ['Generous Commissions:</b> Enjoy competitive commissions for every sale you make. The more you sell, the more you earn. Its that simple!'];


  // checkList3: any[] = ['Effortless Transactions:** We take care of payment processing and logistics, so you can focus on what you do best – selling!'];



  constructor(private scrollingToSection : SystemServices) { }

  ngOnInit() {
  }

  scrollToPage() {
      this.scrollingToSection.moveToActiveSection('Contact Us');

  }

}
