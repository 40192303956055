import { Component, OnInit } from '@angular/core';
import { SystemServices } from 'src/app/app.services/system.services';

@Component({
  selector: 'app-join-us',
  templateUrl: './join-us.component.html'
})
export class JoinUsComponent implements OnInit {

  checkList1: any[] = [
    'Global Exposure: Expand your reach and connect with a worldwide customer base.',
    'Lucrative Commissions: Maximize earnings with competitive commission structures.',
  ]
  constructor(private scrollingToSection : SystemServices) { }

  ngOnInit() {
  }

  scrollToPage() {
    this.scrollingToSection.moveToActiveSection('Contact Us');
  }


}
