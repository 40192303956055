import { Component, OnInit } from '@angular/core';
import { PluginsService } from "../../xamin/plugins.service";
import { topMenuBarItems } from '../../../constants/menu';
import { SystemServices } from 'src/app/app.services/system.services';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
})
export class IndexComponent implements OnInit {
  
  private goToSection : any;

  logoImage = './assets/images/uruk_logo.png';
  contactInfo: any = {
    contactNumber: '+1 (212) 660-4990',
    email: 'uruksupport@urukecommerce.com'
  };

  constructor(private plugins: PluginsService,
              private scrollSection: SystemServices) { }

  public navItems: any = topMenuBarItems;


  ngOnInit() {

    this.scrollSection.getStatusObservable().subscribe((section) => {
        this.scrollToSection(section)
    });

    // Init all plugins...
    const current = this;
    current.plugins.revolutionSlider();
    // tslint:disable-next-line:only-arrow-functions
    setTimeout(function() {
      current.plugins.index();
    }, 200);
  }

  scrollToSection(moveToSection: any) {

    const menuHeight = 70; // Replace with your known menu height
    const scrollToSectionPage = moveToSection.trim();

    switch (scrollToSectionPage) {
      case 'Home':
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return ;
      case "Sellers":
        this.goToSection = document.getElementById('sellers');
        break;
      case 'Warehouses':
        this.goToSection = document.getElementById('provider');
        break;
      case 'Retailers':
        this.goToSection = document.getElementById('retailer');
        break;
      case 'Contact Us':
        this.goToSection = document.getElementById('contact-form');
        break;
      default:
        window.scrollTo({ top: 0, behavior: 'smooth' });
        return;
    }

    const adjustedScrollPosition = this.goToSection.getBoundingClientRect().top - menuHeight;
    const scrollOptions = {
      behavior: 'smooth', // This makes the scrolling smooth
    };

      // Scroll to the adjusted position within the element
    window.scrollTo({
      top: window.scrollY + adjustedScrollPosition,
      behavior: 'smooth',
    });
  }

}
