import { Component, OnInit } from '@angular/core';
import { SystemServices } from 'src/app/app.services/system.services';

@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html'
})
export class ProviderComponent implements OnInit {

  titleSectionProp1: any = {
    title: 'Providers why choose us',
    subTitle: 'For Warehouses & Distributors',
    description: "At Uruk Ecommerce, we're committed to empowering providers with an array of unparalleled benefits, designed to amplify your product's reach and increase sales."
  };

  checkList1: any[] = [
    
    "Expand Your Reach: Partnering with Uruk Ecommerce means your products can reach a vast network of sellers and retailers. We'll introduce your products to a whole new world of potential customers.",

    "Increased Sales: Our platform ensures that your products are front and center for motivated sellers, leading to increased sales volume and revenue.",

    "Minimal Risk: With Uruk, you only pay a commission for actual sales. No listing fees or upfront costs, which means you can grow your business without financial stress.",
 

]


  checkList2: any[] = ['Generous Commissions:</b> Enjoy competitive commissions for every sale you make. The more you sell, the more you earn. Its that simple!'];

  constructor(private scrollingToSection : SystemServices) { }

  ngOnInit() {
  }

  scrollToPage() {
    this.scrollingToSection.moveToActiveSection('Contact Us');
  }

}
