import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SystemServices } from 'src/app/app.services/system.services';
import { NgxSpinnerService } from "ngx-bootstrap-spinner";
import {SnotifyService} from 'ng-snotify';
import {SnotifyPosition, SnotifyStyle} from 'ng-snotify';


@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
})
export class ContactFormComponent implements OnInit {

  public loading: boolean = false;
  public haveError: boolean = false;
  public errorMessage : string = '"Apologies, an error occurred. Please try again later."'
  public maintenanceForm   : FormGroup;
  public whoIs : Array<{option: string, value: string}> = [
    { option: "SELLER", value: 'S' },
    { option: "WAREHOUSE-PROVIDER", value: 'W' },
    { option: "RETAILER", value: 'R' },
    { option: "OTHER", value: 'O' },
    ]

  constructor(private _formBuilder    : FormBuilder, 
               private _systemServices: SystemServices,
               private _displayAlert: SnotifyService,
               private spinner: NgxSpinnerService)  {  }

  ngOnInit() {
    this.setupForm();
  }

  setupForm() {
    this.maintenanceForm = this._formBuilder.group({
      message_customer_name : ['', [Validators.required, Validators.minLength(2), Validators.maxLength(70)]],
      message_who_is        : ['', [Validators.required, Validators.maxLength(20)]],
      message_email         : ['', [Validators.required, Validators.minLength(2), Validators.maxLength(70)]],
      message_phone         : ['', [Validators.required, Validators.minLength(10), Validators.maxLength(20)]],
      message_message       : ['', [Validators.required, Validators.minLength(2), Validators.maxLength(500)]],
    });
  }

  isValidEmail(email: string): boolean {
    // Regular expression pattern for validating email addresses
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  
    return emailPattern.test(email);
  }

  sendMessage() {
    this.haveError = false;
    this._systemServices.insertMessages(this.maintenanceForm)
      .subscribe(
        data => {
          this.spinner.hide();    
          this._displayAlert.success('Your message has been sent successfully.', 'Message Sent', {
            position: SnotifyPosition.rightTop,
            timeout: 2000,
            showProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
          });
          this.maintenanceForm.reset();
        },
        err => {
          this.spinner.hide();    
          this.haveError = true;
          this._displayAlert.error('Can not send your message at this moment', 'Message Error', {
            position: SnotifyPosition.rightTop,
            timeout: 2000,
            showProgressBar: true,
            closeOnClick: false,
            pauseOnHover: true,
          });
        }
    ); 

 }




}
