import {Component, Input, OnInit} from '@angular/core';
import { SystemServices } from 'src/app/app.services/system.services';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  @Input() logoId: boolean;
  @Input() logoImg: string;
  @Input() navItemList: any[];
  @Input() contactInfo: any;
  @Input() headeClass: string;
  constructor(private gotoToSection: SystemServices) { }

  ngOnInit() {
  }

  goToSectionPage(sectionName: string) {
    // Change the status in the child module
    // Notify the parent module about the status change
    this.gotoToSection.moveToActiveSection(sectionName);
  }

}
