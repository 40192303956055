export const environment = {
  production: true,
  APP_ACCESS_KEY: "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyaWQiOjEsImxvZ2luaWQiOiJkaW9AZHF1ZXphZGEubWUiLCJpYXQiOjE2OTc5MTcyODEsImF1ZCI6Imh0dHA6Ly9ncmF2aXR5Y29kZXIuY29tIiwiaXNzIjoiR3Jhdml0eSBDb2RlciwgTExDIiwic3ViIjoiZGV2ZWxvcGVyQGdyYXZpdHljb2Rlci5jb20ifQ.QvZGIYgef5E2kwZFdsu9RZZLCAvhr4Et-1vidYA-CbZIhAtt-o8NCeMPFXLFvKJDGmZ5q7g-MsPXwhAkReqtz31oTveCaHA2RZrCTlobYUnzijPHc0tYfaDyJuQNJw8pJaF1TTcw-Sh2UrhsZsTFh5iu7XiGPEZuToEwJ2pGP4w",
  APP_LOGIN: "PagosEstatalesAdm",
  APP_PASSWORD: "85u9QftNwJW83BMAwvPq"
};

export const paypalSetup = {
  PRODUCTION_KEY : '',
  SANDBOX_KEY : 'AWRUbaWceUX3osO6YCECVkHpBdY-jojQO70BeCeFo4rRX41UnP2DYxzkZZB0JoNMzPL2gZg6PYnPErX1'
}
